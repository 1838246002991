// ============================
//     Sidebar css start
// ============================

.dash-sidebar {
  background: $sidebar-background;
  box-shadow: $sidebar-shadow;
  width: $sidebar-width;
  position: fixed;
  top: 0;
  bottom: 0;
  z-index: 1026;
  @media (min-width: 1024px) {
    &.light-sidebar.transprent-bg {
      background: transparent;
      box-shadow: none;
      .navbar-content {
        height: calc(100vh - #{$header-height});
      }
    }
    &.light-sidebar:not(.transprent-bg) {
      top: 20px;
      bottom: 20px;
      left: 20px;
      border-radius: 20px;
      box-shadow: -2.61824px 26px 49px rgba(182, 186, 203, 0.3);
      .navbar-content {
        $temph: $header-height + 40;
        height: calc(100vh - #{$temph});
      }
    }
  }

  ul {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
  }

  .dash-caption {
    color: $sidebar-caption;
    display: block;
    padding: 20px 20px 8px;
    letter-spacing: 0.07em;
    text-transform: uppercase;

    font: {
      size: 11px;
      weight: 700;
    }

    label {
      margin-bottom: 0;
    }

    span:not(.badge) {
      display: block;
      color: #67758a;

      font: {
        size: 10px;
        weight: 500;
      }

      text-transform: capitalize;
    }
  }

  .dash-micon {
    margin-right: 15px;
    border-radius: 12px;
    height: 35px;
    width: 35px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    vertical-align: middle;

    i {
      font-size: 18px;
      color: $sidebar-icon-color;

      &.material-icons-two-tone {
        background-color: $sidebar-icon-color;
        font-size: 20px;
        display: inline-block;
      }
    }

    > svg {
      width: 18px;
      height: 18px;
      color: $sidebar-icon-color;
      fill: rgba(114, 103, 239, 0.2);
    }
  }

  .dash-link {
    display: block;
    padding: 13px 20px;
    color: $sidebar-color;
    position: relative;
    .dash-mtext {
      &::after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
      }
    }
    font: {
      size: 13px;
      weight: 600;
    }

    &:active,
    &:focus,
    &:hover {
      text-decoration: none;
      color: $sidebar-active-color;

      .dash-micon {
        i,
        svg {
          color: $sidebar-active-color;
        }
      }
    }
  }

  .dash-arrow {
    float: right;
    display: inline-block;
    transition: all 0.2s ease-in-out;

    > svg {
      width: 16px;
      height: 16px;
    }
  }

  .dash-badge {
    margin-left: 8px;
  }

  .m-header {
    height: $header-height;
    display: flex;
    align-items: center;
    padding: 15px 20px;

    .logo-sm {
      display: none;
    }
  }

  .navbar-content {
    position: relative;
    padding: 10px 0;
    .card {
      margin: 40px 20px 70px;
      position: relative;
      .img-sidebar-card {
        margin: 20px 0 -65px;
        width: 100%;
      }
    }
  }

  .dash-submenu {
    // display: none;
    // &:not(.dash-trigger){
    //     display: none;
    // }
    .dash-link {
      font-weight: 500;
      padding: 10px 30px 10px 65px;
    }

    .dash-submenu {
      .dash-link {
        padding: 10px 30px 10px 85px;
      }

      .dash-submenu {
        .dash-link {
          padding: 10px 30px 10px 105px;
        }
      }
    }
  }

  .dash-item {
    &.disabled {
      a {
        cursor: not-allowed;
        user-select: none;
      }
    }

    &.active,
    &:focus,
    &:hover {
      > .dash-link {
        color: $sidebar-main-active-color;

        .dash-micon {
          i.material-icons-two-tone {
            background-color: $sidebar-main-active-color;
          }

          i,
          svg {
            color: $sidebar-main-active-color;
          }
        }
      }
    }

    > .dash-submenu .dash-item {
      &.active,
      &:focus,
      &:hover {
        > .dash-link {
          font-weight: 700;
          color: $sidebar-active-color;

          .dash-micon {
            i,
            svg {
              color: $sidebar-active-color;
            }
          }
        }
      }
    }
  }

  .dash-navbar {
    > .dash-item {
      &.active,
      &:focus,
      &:hover {
        > .dash-link {
          background: $brand-color;
        }
      }
    }
  }

  .dash-hasmenu {
    &.dash-trigger {
      > .dash-submenu {
        display: block;
        // display: block !important;
      }

      > .dash-link {
        > .dash-arrow {
          transform: rotate(90deg);
        }
      }
    }
  }
}

body:not(.minimenu) {
  .dash-sidebar {
    .dash-link {
      margin: 7px 0;
    }

    .dash-submenu {
      position: relative;

      .dash-item {
        position: relative;

        &:before {
          content: "";
          position: absolute;
          left: 40px;
          top: 16px;
          width: 8px;
          height: 8px;
          border: 2px solid $sidebar-color;
          border-right-color: $sidebar-active-color;
          border-radius: 50%;
          z-index: 1;
          transform: rotate(45deg);
          transition: 0.35s cubic-bezier(0.54, 1.6, 0.5, 1);
          color: $sidebar-icon-color;
        }

        &.active {
          &:before {
            // color: $primary;
            transform: rotate(230deg);
          }
        }

        &:hover {
          &:before {
            // left: 45px;
            transform: rotate(230deg);
          }
        }
      }

      .dash-submenu {
        > .dash-item {
          &:before {
            left: 60px;
          }
        }

        .dash-submenu {
          > .dash-item {
            &:before {
              left: 80px;
            }
          }
        }
      }
    }
  }
}

.dash-menu-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1;
  background: rgba(0, 0, 0, 0.2);
}

.minimenu {
  .dash-sidebar {
    width: $sidebar-collapsed-width;

    .dash-hasmenu.dash-trigger:not(:hover) > .dash-submenu,
    .dash-hasmenu:not(:hover) > .dash-submenu {
      display: none;
    }

    .m-header {
      padding: 15px 17px;

      .logo-sm {
        display: block;
      }

      .logo-lg {
        display: none;
      }
    }

    .dash-badge,
    .dash-caption,
    .dash-mtext,
    .dash-navbar > li > a > .dash-arrow {
      display: none;
    }

    .navbar-content {
      &.ps {
        overflow: visible !important;

        > .ps__rail-X,
        > .ps__rail-y {
          display: none;
        }
      }
    }

    .dash-link {
      padding: 20px 25px;
    }

    .dash-submenu {
      background: $sidebar-background;
      position: absolute;
      left: 100%;
      padding: 8px 0;

      .dash-link {
        padding: 8px 30px;
      }
    }

    .dash-item {
      position: relative;

      &:hover {
        > .dash-link {
          width: calc(200px + #{$sidebar-collapsed-width});
          background: $brand-color;
          color: $sidebar-active-color;

          .dash-micon {
            i {
              color: #fff;
            }

            margin-right: 40px;
          }

          .dash-mtext {
            display: inline-block;
          }
        }

        > .dash-submenu {
          display: block;
          width: 200px;
        }
      }

      .dash-item {
        &:hover {
          > .dash-link {
            width: 200px;
            background: $sidebar-background;
          }

          > .dash-submenu {
            top: 0;
          }
        }
      }
    }
  }
}

@media (min-width: 1025px) {
  .navbar-overlay {
    .dash-sidebar {
      &.dash-over-menu-active {
        .navbar-wrapper {
          position: relative;
          z-index: 5;
        }
      }

      &:not(.dash-over-menu-active) {
        left: -#{$sidebar-width};
        box-shadow: none;
      }
    }
  }
}

@media (max-width: 1024px) {
  .dash-sidebar {
    left: -#{$sidebar-width};
    box-shadow: none;
    transition: all 0.15s ease-in-out;

    &.mob-sidebar-active {
      left: 0;
      box-shadow: $sidebar-shadow;

      .navbar-wrapper {
        position: relative;
        z-index: 5;
        background: inherit;
      }
    }
  }

  .minimenu {
    .dash-container {
      margin-left: 0;
    }
  }
}

// Sidebar css end
