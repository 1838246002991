/**  =====================
      Custom css start
==========================  **/
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&display=swap");

body {
  min-height: 100vh;
  overflow-x: hidden;
}
* {
  &:focus {
    outline: none;
  }
}

a {
  &:hover {
    outline: none;
    text-decoration: none;
  }

  &:not([href]) {
    color: inherit;
  }
}

p {
  font-size: 14px;
}

b,
strong {
  font-weight: 600;
}

.ie-warning {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999;
  background: #000000;
  width: 100%;
  height: 100%;
  text-align: center;
  color: #fff;
  font-family: "Courier New", Courier, monospace;
  padding: 50px 0;

  p {
    font-size: 17px;
  }

  h1 {
    color: #fff;
  }

  .iew-container {
    min-width: 1024px;
    width: 100%;
    height: 200px;
    background: #fff;
    margin: 50px 0;
  }

  .iew-download {
    list-style: none;
    padding: 30px 0;
    margin: 0 auto;
    width: 720px;

    > li {
      float: left;
      vertical-align: top;

      > a {
        display: block;
        color: #000;
        width: 140px;
        font-size: 15px;
        padding: 15px 0;

        > div {
          margin-top: 10px;
        }

        &:hover {
          background-color: #eee;
        }
      }
    }
  }
}

.btn-page {
  .btn-group {
    .btn {
      margin-right: 0;
      margin-bottom: 0;
    }
  }

  .btn {
    margin-right: 5px;
    margin-bottom: 5px;
  }
}

.material-icons-two-tone {
  background-color: $body-color;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  vertical-align: text-bottom;

  &.text-white {
    background-color: #fff;
  }
}
// ==============  highlight code css start  ================

.hljs {
  display: block;
  overflow-x: auto;
  padding: 0.5em;
  color: #abb2bf;
  background: #282c34;
}

.hljs-comment,
.hljs-quote {
  color: #5c6370;
  font-style: italic;
}

.hljs-doctag,
.hljs-formula,
.hljs-keyword {
  color: #c678dd;
}

.hljs-deletion,
.hljs-name,
.hljs-section,
.hljs-selector-tag,
.hljs-subst {
  color: #e06c75;
}

.hljs-literal {
  color: #56b6c2;
}

.hljs-addition,
.hljs-attribute,
.hljs-meta-string,
.hljs-regexp,
.hljs-string {
  color: #98c379;
}

.hljs-built_in,
.hljs-class .hljs-title {
  color: #e6c07b;
}

.hljs-attr,
.hljs-number,
.hljs-selector-attr,
.hljs-selector-class,
.hljs-selector-pseudo,
.hljs-template-variable,
.hljs-type,
.hljs-variable {
  color: #d19a66;
}

.hljs-bullet,
.hljs-link,
.hljs-meta,
.hljs-selector-id,
.hljs-symbol,
.hljs-title {
  color: #61aeee;
}

.hljs-emphasis {
  font-style: italic;
}

.hljs-strong {
  font-weight: bold;
}

.hljs-link {
  text-decoration: underline;
}
// ==============  highlight code css end  ================

/* ========================================================
 ===============     document      ======================
 ========================================================

 Grid examples
*/
.bd-example-row {
  background: $body-bg;
  padding: 15px;
  margin-bottom: 15px;

  .row {
    > [class^="col-"],
    > .col {
      padding-top: 0.75rem;
      padding-bottom: 0.75rem;
      background-color: rgba(86, 61, 124, 0.15);
      border: 1px solid rgba(86, 61, 124, 0.2);
    }
  }

  .row + .row {
    margin-top: 1rem;
  }

  .flex-items-bottom,
  .flex-items-middle,
  .flex-items-top {
    min-height: 6rem;
    background-color: rgba(255, 0, 0, 0.1);
  }
}

.bd-example-row-flex-cols .row {
  min-height: 10rem;
  background-color: rgba(255, 0, 0, 0.1);
}
/* Docs examples */
.bd-example {
  position: relative;
  padding: 1rem;
  margin: 1rem;
  border: solid #ddd;
  border-width: 0.2rem 0 0;
  @media only screen and (max-height: 575px) {
    padding: 1.5rem;
    margin-right: 0;
    margin-left: 0;
    border-width: 0.2rem;
  }
}
/* Example modals */
.modal {
  z-index: 1072;

  .popover,
  .tooltip {
    z-index: 1073;
  }
}

.modal-backdrop {
  z-index: 1071;
}

.bd-example-modal {
  background-color: $body-bg;

  .modal {
    position: relative;
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
    z-index: 1;
    display: block;
  }

  .modal-dialog {
    left: auto;
    margin-right: auto;
    margin-left: auto;
  }
  .offcanvas {
    position: static;
    display: block;
    height: 200px;
    visibility: visible;
    transform: translate(0);
  }
}
/* Example tabbable tabs */
.bd-example-tabs .nav-tabs {
  margin-bottom: 1rem;
}
/* Code snippets */
.highlight {
  padding: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  background-color: #f3f2fb;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  @media only screen and (max-height: 575px) {
    padding: 1.5rem;
  }
}

.bd-content .highlight {
  margin-right: 15px;
  margin-left: 15px;
  @media only screen and (max-height: 575px) {
    margin-right: 0;
    margin-left: 0;
  }
}

.highlight {
  pre {
    padding: 0;
    margin-top: 0;
    margin-bottom: 0;
    background-color: transparent;
    border: 0;
  }

  pre code {
    font-size: inherit;
    color: #333;
  }
}

:not(pre) > code[class*="language-"],
pre[class*="language-"] {
  background: transparent;
}
/* ================================    Blockquote Start  ===================== */

svg.feather {
  &:not([class*="wid-"]) {
    width: 20px;
  }

  &:not([class*="hei-"]) {
    height: 20px;
  }
}
@media (min-width: 1600px) {
  .container {
    max-width: 1540px;
  }
}

pre[class*="language-"]:before,
pre[class*="language-"]:after {
  display: none;
}

:not(pre) > code[class*="language-"],
pre[class*="language-"] {
  display: flex;
  margin: 0;
}

pre[class*="language-"] > code {
  width: 100%;
}

.media {
  display: flex;

  .media-body {
    flex: 1;
  }
}

.blockquote {
  border-left: 0.25rem solid $border-color;
  padding: 0.5rem 1rem;

  &.text-end {
    border-left: none;
    border-right: 0.25rem solid $border-color;
  }
}

.breadcrumb {
  svg.feather {
    width: 16px;
    height: 16px;
    vertical-align: baseline;
  }
}

[data-notify] .close {
  border: 0;
  opacity: 0.5;
  width: 1em;
  height: 1em;
  margin-top: 5px;
  color: transparent !important;
  background: transparent escape-svg($btn-close-bg) center / $btn-close-width auto no-repeat;
  filter: invert(1) grayscale(100%) brightness(200%);
}
/* ================================    Blockquote End  ===================== */
a[data-lightbox] {
  cursor: pointer;
}
pre[class*="language-"] > code {
  box-shadow: 0px 0px 0px 1px #dfdfdf;
  border-left: 5px solid;
}
.offcanvas {
  z-index: 1075;
}
.dash-sidebar .accordion-item {
  border: none;
}
.form-switch.form-switch-right {
  padding-left: 0;
  margin-bottom: 0;
  .form-check-input {
    float: right;
  }
}
// / theme color scss start
$i: 1;
@each $name, $value in $themes-colors {
  body {
    &.theme-#{$i} {
      background: map-get($value, "body");

      .bg-primary {
        background: map-get($value, "primary-grd"), map-get($value, "primary") !important;
      }
      .dash-header {
        .drp-language .drp-text,
        .dash-head-link > i:not(.nocolor) {
          color: map-get($value, "primary");
        }
        .drp-company .theme-avtar {
          background: shift-color(map-get($value, "primary"), $soft-bg-level);
          color: map-get($value, "primary");
        }
      }
      .dash-sidebar {
        &.light-sidebar .dash-navbar,
        .dash-navbar {
          > .dash-item {
            &.active,
            &:active,
            &:focus,
            &:hover {
              > .dash-link {
                background: map-get($value, "primary-grd"), map-get($value, "primary");
                color: #fff;
                box-shadow: 0 5px 7px -1px transparentize(map-get($value, "primary"), 0.7);
                i {
                  color: map-get($value, "primary");
                }
              }
            }
          }
        }
        &.light-sidebar .dash-item,
        .dash-item {
          .dash-submenu {
            .dash-item {
              &::before {
                border-right-color: map-get($value, "primary");
              }
              &.active,
              &:active,
              &:focus,
              &:hover {
                > .dash-link {
                  color: map-get($value, "primary");
                }
              }
            }
          }
        }
      }

      a:not([class]) {
        color: map-get($value, "primary");

        &:hover {
          color: map-get($value, "primary");
        }
      }
      .text-primary {
        color: map-get($value, "primary") !important;
      }
      .list-group-item.active {
        background: map-get($value, "primary-grd"), map-get($value, "primary") !important;
      }
      .progress-bar:not([class*="bg-"]),
      .btn-primary {
        @include button-variant(map-get($value, "primary"), map-get($value, "primary"));
      }
      .btn-outline-primary {
        @include button-outline-variant(map-get($value, "primary"));
      }
      .btn-light-primary {
        background: shift-color(map-get($value, "primary"), $soft-bg-level);
        color: map-get($value, "primary");
        border-color: shift-color(map-get($value, "primary"), $soft-bg-level);

        &:hover {
          background: map-get($value, "primary");
          color: #fff;
          border-color: map-get($value, "primary");
        }

        &.focus,
        &:focus {
          background: map-get($value, "primary");
          color: #fff;
          border-color: map-get($value, "primary");
        }

        &:not(:disabled):not(.disabled).active,
        &:not(:disabled):not(.disabled):active,
        .show > &.dropdown-toggle {
          background: map-get($value, "primary");
          color: #fff;
          border-color: map-get($value, "primary");
        }
      }

      .btn-check:active,
      .btn-check:checked {
        + .btn-light-primary {
          background: map-get($value, "primary");
          color: #fff;
          border-color: map-get($value, "primary");
        }
      }
      .alert-primary {
        $alert-background: shift-color(map-get($value, "primary"), $alert-bg-scale);
        $alert-border: shift-color(map-get($value, "primary"), $alert-border-scale);
        $alert-color: shift-color(map-get($value, "primary"), $alert-color-scale);
        @if (contrast-ratio($alert-background, $alert-color) < $min-contrast-ratio) {
          $alert-color: mix(map-get($value, "primary"), color-contrast($alert-background), abs($alert-color-scale));
        }
        @include alert-variant($alert-background, $alert-border, $alert-color);
      }
      .badge.bg-light-primary {
        background: shift-color(map-get($value, "primary"), $soft-bg-level);
        color: map-get($value, "primary");
        border-color: shift-color(map-get($value, "primary"), $soft-bg-level);
      }
      .page-item {
        &.active .page-link {
          background: map-get($value, "primary");
          border-color: map-get($value, "primary");
        }
      }

      .nav-link {
        color: map-get($value, "primary");
        &:hover,
        &:focus {
          color: map-get($value, "primary");
        }
      }
      .nav-tabs {
        .nav-link {
          background: none;
        }
      }

      .nav-pills {
        .nav-link {
          background: none;
        }
        // .nav-link:hover,
        .nav-link:focus,
        .nav-link.active,
        .show > .nav-link {
          color: $nav-pills-link-active-color;
          background: map-get($value, "primary-grd"), map-get($value, "primary");
        }
      }
      .form-check-input,
      .form-select,
      .form-control {
        &:focus {
          border-color: map-get($value, "primary");
          box-shadow: 0 0 0 0.2rem rgba(map-get($value, "primary"), 0.25);
        }
      }
      .form-check-input:checked {
        background-color: map-get($value, "primary");
        border-color: map-get($value, "primary");
      }

      // .form-check-input:focus {
      //   box-shadow: 0 0 0 0.2rem rgba(map-get($value, "primary"), 0.25);
      // }
      .form-range {
        &::-webkit-slider-thumb {
          background: map-get($value, "primary");

          &:active {
            background: map-get($value, "primary");
          }
        }

        &::-moz-range-thumb {
          background: map-get($value, "primary");

          &:active {
            background: map-get($value, "primary");
          }
        }
      }

      .form-check-input {
        &:checked {
          background-color: map-get($value, "primary");
          border-color: map-get($value, "primary");
        }

        &[type="checkbox"]:indeterminate {
          background-color: map-get($value, "primary");
          border-color: map-get($value, "primary");
        }
      }
      .form-check .form-check-input.input-primary:checked {
        background-color: map-get($value, "primary");
        border-color: map-get($value, "primary");
      }
      pre[class*="language-"] > code {
        border-left-color: map-get($value, "primary");
        background-image: linear-gradient(transparent 50%, rgba(map-get($value, "primary"), 0.04) 50%);
      }
      .card .card-header h5:after,
      .card .card-header .h5:after {
        background: map-get($value, "primary");
      }
      // ======================
    }
  }
  $i: $i + 1;
}
// / theme color scss end
