// ============================
//     header css start
// ============================
.dash-header {
  background: $header-background;
  box-shadow: $header-shadow;
  color: $header-color;
  min-height: $header-height;
  position: absolute;
  left: $sidebar-width;
  right: 0;
  z-index: 1025;

  @media (min-width: 1024px) {
    &.transprent-bg {
      background: transparent;
      box-shadow: none;
    }

    &:not(.transprent-bg) {
      top: 20px;
      left: calc(#{$sidebar-width} + 40px);
      right: 30px;
      border-radius: 20px;
      box-shadow: -2.61824px 26px 49px rgba(182, 186, 203, 0.3);

      .header-wrapper {
        padding: 0 12px;
      }

      &:not(.dash-mob-header) ~ .dash-container {
        margin-left: calc(#{$sidebar-width} + 15px);

        .dash-content {
          padding-top: 50px;
        }
      }
    }
  }

  .burger-menu {
    position: relative;
    width: 15px;
    height: 15px;

    > span {
      height: 2px;
      border-radius: 2px;
      width: 10px;
      position: absolute;
      right: 0;
      top: calc(50% - 1px);
      background: $body-color;

      &:first-child {
        width: 15px;
        top: 0;
      }

      &:last-child {
        width: 5px;
        top: auto;
        bottom: 0;
      }
    }
  }

  .header-search {
    box-shadow: $card-shadow;
    border-radius: $border-radius-sm;
    padding: 4px 8px;
    background: $input-bg;

    .btn-head-search {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      box-shadow: -3px 4px 23px rgba(0, 0, 0, 0.08);
      border-radius: $border-radius-sm;
      width: 30px;
      height: 30px;
      padding: 15px 16px;

      i {
        font-size: 22px;
      }
    }
  }

  ul {
    margin-bottom: 0;
    display: inline-flex;
  }

  .header-wrapper {
    display: flex;
    padding: 0 30px;
  }

  .dash-h-item {
    min-height: $header-height;
    display: flex;
    align-items: center;
    position: relative;
  }

  .dash-head-link {
    color: $header-color;
    display: inline-flex;
    align-items: center;
    padding: 0.6rem 0.7rem;
    margin: 0 7.5px;
    border-radius: 4px;
    position: relative;
    font-weight: 500;
    border-radius: 12px;
    border: 1px solid transparentize($header-item-border-color, 0.8%);
    @media (min-width: 1024px) {
      background: $header-background;
    }

    > i {
      font-size: 20px;
      color: $primary;

      &.material-icons-two-tone {
        font-size: 24px;
        background-color: $header-color;
      }
    }

    > svg {
      width: 20px;
      height: 20px;
    }

    &.active,
    &:active,
    &:focus,
    &:hover {
      text-decoration: none;
      color: $primary;
      background: transparentize($secondary, 0.95);

      .hamburger {
        .hamburger-inner {
          background-color: $primary;

          &::after,
          &::before {
            background-color: $primary;
          }
        }
      }

      i.material-icons-two-tone {
        background-color: $primary;
      }
    }

    .dash-h-badge {
      position: absolute;
      top: 3px;
      right: 10px;
      border-radius: 50%;
      font-size: 9px;

      &.dots {
        width: 8px;
        height: 8px;
        top: 10px;
        right: 12px;
        padding: 0;
        border: 2px solid $header-background;
      }
    }

    .user-desc,
    .user-name {
      display: block;
      line-height: 1;
    }

    .user-name {
      margin-bottom: 5px;

      font: {
        size: 15px;
        weight: 600;
      }
    }

    .user-desc {
      font: {
        size: 12px;
        weight: 400;
      }

      color: transparentize($header-color, 0.3);
    }
    .drp-arrow {
      color: $header-color;
      font-size: 14px;
      margin-left: 5px;
      opacity: 0.8;
    }
    .theme-avtar {
      border-radius: 12px;
      font-size: 16px;
      width: 34px;
      height: 34px;
      background: shift-color($primary, $soft-bg-level);
      color: $primary;
    }
  }
  .drp-language {
    i {
      color: $header-item-border-color;
    }
    .drp-text {
      color: $primary;
      margin-left: 8px;
    }
  }
  .drp-company {
    .dash-head-link {
      padding: 5px;
    }
  }
  .drp-notification {
    .theme-avtar {
      border-radius: 12px;
    }
    .dropdown-menu {
      min-width: 450px;
    }
    .noti-header,
    .noti-footer,
    .noti-body {
      padding: 10px 20px;
    }
    .noti-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .noti-body {
      position: relative;
      max-height: calc(100vh - 300px);
    }
  }
  .dash-h-dropdown {
    .fa-circle {
      font-size: 5px;
      vertical-align: middle;
    }

    transform: none !important;
    top: 100% !important;

    &.dropdown-menu-end {
      right: 0 !important;
      left: auto !important;
    }
  }

  .dash-level-menu {
    position: relative;

    &:hover {
      > .dropdown-menu {
        display: block;
        left: 100%;
        top: -18px !important;
      }
    }
  }

  .dash-mega-menu {
    position: static;

    .dash-mega-dmenu {
      transform: none !important;
      left: 0 !important;
      right: 0 !important;
      top: 100% !important;
      padding: 0;

      .row.g-0 {
        .col {
          padding: 15px 0;
          border-right: 1px dashed $border-color;
        }
      }

      .mega-title {
        margin: 10px 25px;
        position: relative;
      }

      .dash-mega-list {
        display: block;
        list-style: none;
        padding-left: 0;
      }
    }
  }

  .drp-search {
    min-width: 20rem;
  }

  #vertical-nav-toggle {
    margin-left: -15px;
  }

  .user-avtar {
    width: 30px;
    margin-right: 10px;
    border-radius: 50%;
  }
}

.minimenu {
  .dash-header {
    left: $sidebar-collapsed-width;
  }
}

.dash-mob-header {
  display: none;
  background: $brand-color;
  height: 70px;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  z-index: 1026;
  left: 0;
  right: 0;
  padding: 0 10px 0 30px;

  .hamburger-inner,
  .hamburger-inner::after,
  .hamburger-inner::before {
    background: #fff;
  }

  .dash-head-link,
  a {
    color: #fff;
  }
}

.notification-modal.modal.fade .modal-dialog {
  max-width: 310px;
  transform: translate(100%, 0);
}

.notification-modal.modal.show .modal-dialog {
  transform: none;
}

.notification-modal {
  padding-right: 0 !important;

  .modal-dialog {
    margin: 0 0 0 auto;
  }

  .modal-content {
    min-height: 100vh;
    border: none;
    border-radius: 0;
    box-shadow: -1px 0 9px -3px rgba(0, 0, 0, 0.6);
  }
}
@media (min-width: 1024px) {
  .dash-header .dash-h-item.mob-hamburger {
    display: none;
  }
}
@media (max-width: 767px) {
  .dash-header .dash-h-item .hide-mob {
    display: none;
  }
}
@media (max-width: 1024px) {
  .mob-hamburger {
    display: none;
  }
  .dash-mob-header {
    display: flex;
  }

  .dash-header {
    top: 0;
    left: 0;
    transition: all 0.15s ease-in-out;
    .header-wrapper {
      padding: 0 15px;
    }
    .user-avtar {
      margin-right: 0;

      ~ span {
        display: none;
      }
    }

    .dash-head-link {
      .user-desc,
      .user-name {
        display: none;
      }

      &#mobile-collapse {
        position: relative;
      }
    }

    .pcm-logo {
      margin-left: 30px;

      img {
        max-width: 160px;
      }
    }

    &.mob-header-active {
      top: $header-height;

      .header-wrapper {
        background: $header-background;
        position: relative;
        z-index: 5;
      }
    }

    .dash-md-overlay {
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      z-index: 1;
      background: rgba(0, 0, 0, 0.2);
    }

    .dash-level-menu .dropdown-menu {
      left: 0;
      display: block;
      padding-left: 30px;
    }

    .dash-mega-menu .dash-mega-dmenu .row.g-0 > .col {
      flex: 100%;
    }

    .dash-mob-drp {
      // transition: left 0.15s ease-in-out;
      // left: -#{$sidebar-width};
      // position: fixed;
      // top: $header-height;
      // width: $sidebar-width;
      // bottom: 0;

      // &.mob-drp-active {
      //   left: 0;
      //   z-index: 1026;
      //   background: $header-background;
      //   box-shadow: $header-shadow;
      //   overflow-y: auto;
      //   padding: 30px 0;

      //   .dash-h-item {
      //     display: block;
      //     min-height: auto;
      //     position: relative;

      //     .dash-head-link {
      //       display: block;
      //       margin: 5px 10px !important;
      //     }

      //     .dropdown-menu {
      //       position: relative !important;
      //       width: 100%;
      //       float: none;
      //       box-shadow: none;
      //     }
      //   }

      //   ul {
      //     display: block;
      //   }
      // }
    }
  }

  .minimenu {
    .dash-header {
      left: 0;
    }
  }
}

#dash-noti-home {
  > .media {
    &:hover {
      margin: -15px;
      padding: 15px;
      background: rgba($primary, 0.1);
    }
  }
}

@include media-breakpoint-down(sm) {
  .dash-header {
    .dash-head-link {
      padding: 0.65rem;
      margin: 0 5px;
    }

    .dash-h-item {
      position: static;

      // .dash-h-dropdown {
      //   left: 0 !important;
      //   right: 0 !important;
      // }
    }
    .drp-notification .dash-h-dropdown {
      min-width: 100%;
      left: 0 !important;
      right: 0 !important;
    }
  }
}

// header css end
