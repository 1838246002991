/**  =====================
      Authentication css start
==========================  **/

.auth-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  min-width: 100%;
  min-height: 100vh;
  // background: $primary;
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px;
    }
  }
  @media (-ms-high-contrast: none), (-ms-high-contrast: active) {
    display: block;
    margin: 0 auto;

    > .row {
      min-height: 100vh;

      > .aut-bg-img {
        min-height: 100vh;
      }
    }
  }

  .saprator {
    position: relative;
    margin: 8px 0;

    span {
      background: #fff;
      position: relative;
      padding: 0 10px;
      z-index: 5;
      font-size: 20px;
    }

    &:after {
      content: "";
      position: absolute;
      top: 15px;
      left: 0;
      width: 100%;
      height: 1px;
      background: $border-color;
      z-index: 1;
    }
  }

  a,
  p > a {
    color: $header-color;
  }

  .input-group {
    background: transparent;
  }

  .card {
    margin-bottom: 0;
    padding: 8px;

    .card-body {
      padding: 20px 25px 20px 40px;
    }
    @media (-ms-high-contrast: none), (-ms-high-contrast: active) {
      display: block;
    }
  }

  > div {
    z-index: 5;
  }

  .auth-content {
    position: relative;
    padding: 15px;
    z-index: 5;

    &:not(.container) {
      .card-body {
        padding: 40px 35px;
      }
    }
    @media (-ms-high-contrast: none), (-ms-high-contrast: active) {
      margin: 0 auto;
    }
  }
  .navbar {
    background: $card-bg;
    border-radius: $border-radius;
    box-shadow: $card-shadow;
  }
  .auth-content {
    width: 75vw;
    min-height: calc(100vh - 40px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .auth-footer {
      .link-light {
        color: #fff;
        opacity: 0.7;
        &:hover,
        &:focus,
        &:active {
          opacity: 1;
        }
      }
    }
    .card {
      overflow: hidden;
      padding: 0;
      box-shadow: none;
      background: transparent;

      .card-body {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        // margin: 0 auto;
        height: 100%;
        width: 400px;
        max-width: 100%;
      }
      .auth-img-content {
        margin: 0 0 0 auto;
        width: 400px;
        max-width: 100%;
      }
    }

    .img-card-side {
      position: relative;
      padding: 0;

      .img-card-side-content {
        position: absolute;
        top: 30px;
        left: 45px;
      }
    }
  }
  @media only screen and (max-width: 1199px) {
    .bg-auth-side,
    .auth-img-content,
    .auth-footer {
      display: none;
    }
    .auth-content {
      width: 85vw;
      .auth-footer {
        display: none;
      }
      .card .card-body {
        margin: 0 auto;
      }
    }
  }
  @media only screen and (max-width: 992px) {
    .auth-content {
      width: 95vw;
    }
  }
  @media only screen and (max-width: 767px) {
    .card {
      .card-body {
        padding: 30px 15px;
      }
    }
  }
  &.auth-v3 {
    .bg-auth-side {
      content: "";
      top: 20px;
      left: 50%;
      right: 20px;
      bottom: 20px;
      border-radius: 20px;
      position: absolute;
    }
  }
  &.auth-v2 {
    .card {
      overflow: visible;
    }
    .bg-auth-side {
      content: "";
      top: 0;
      left: 50%;
      right: 0;
      bottom: 0;
      border-radius: 0px;
      position: absolute;
      transform: skewX(-8deg);
      transform-origin: bottom;
    }
    .auth-img-content {
      position: relative;
      height: 50vh;
      width: 100%;
    }
    .img-widget-1,
    .img-widget-2,
    .img-widget-3,
    .img-widget-4 {
      position: absolute;
    }
    .img-widget-1 {
      top: -25px;
      left: -110px;
      width: 300px;
      animation: widanimx 12s ease-in-out infinite;
    }
    .img-widget-2 {
      bottom: -10%;
      left: 50%;
      width: 400px;
      animation: widanimx 12s ease-in-out infinite;
      animation-delay: 2.15s;
    }
    .img-widget-3 {
      bottom: -10%;
      left: -30%;
      width: 300px;
      animation: widanimy 12s ease-in-out infinite;
    }
    .img-widget-4 {
      top: -10%;
      left: 50%;
      width: 300px;
      animation: widanimy 12s ease-in-out infinite;
      animation-delay: 2.15s;
    }
    .img-ring {
      width: 200px;
      height: 200px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 50%;
      background: rgba(255, 255, 255, 0.25);
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 100px;
      }
      &:after,
      &:before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        border-radius: 50%;
        background: rgba(255, 255, 255, 0.25);
      }
      &:after {
        animation: sunwawe 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
      }
      &:before {
        animation: sunwawe 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
        animation-delay: 1.15s;
      }
    }
  }
  &.auth-v1 {
    .navbar {
      background: transparent;
    }
    .card {
      background: $card-bg;
      box-shadow: $box-shadow;
    }
    .bg-auth-side {
      content: "";
      top: 0;
      left: 0;
      right: 0;
      bottom: 40%;
      position: absolute;
    }
    @media only screen and (max-width: 1199px) {
      .bg-auth-side {
        display: block;
      }
    }
  }
}
@keyframes sunwawe {
  0% {
    transform: scale(1);
    opacity: 1;
  }

  100% {
    transform: scale(4);
    opacity: 0;
  }
}
@keyframes widanimx {
  0% {
    transform: translate(25px, 0px);
  }

  50% {
    transform: translate(-15px, 0px);
  }

  100% {
    transform: translate(25px, 0px);
  }
}
@keyframes widanimy {
  0% {
    transform: translate(0px, 25px);
  }

  50% {
    transform: translate(0px, -15px);
  }

  100% {
    transform: translate(0px, 25px);
  }
}
/**====== Authentication css end ======**/
